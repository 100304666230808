import axios from 'axios' // 一个基于 promise 的 HTTP 库   npm install axios即可安装
import Router from '../router'
import {
	ElMessage,
	ElMessageBox
} from 'element-plus' // 这里用的element-ui中的ElMessage消息提示插件

// axios.defaults.baseURL = 'http://192.168.6.19:8080' //光强服务器
// axios.defaults.baseURL = 'http://192.168.6.160:8080' //晓磊服务器
// axios.defaults.baseURL = 'https://demo.boruankeji.net:60105' //测试服务器
// axios.defaults.baseURL = 'http://www.dilcdevicehost.com/index.php/' //测试服务器
axios.defaults.baseURL = 'https://system.zrms.com.cn' //正式服务器

// 每一次请求不可能都要手动添加一个参数吧，所以在这里定义一个拦截器，请求发送，在这里自动将请求拦截下来，自动的往请求里面加一个参数
axios.interceptors.request.use(config => {
	let token = sessionStorage.getItem('token')
	config.headers.common['indexToken'] = token ? token : '';
	return config
}, err => {
	ElMessage.error({
		message: '请求超时'
	})
	return Promise.resolve(err)
})
// 拦截服务端响应的信息
axios.interceptors.response.use(data => {
	if (data.status && data.status === 200 && data.data.code !== 200) {
		if (data.data.code === 7000) { //对话框
			ElMessageBox.confirm(data.data.message, '温馨提示', {
				confirmButtonText: '知道了',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {}).catch(() => {})
		} else { //吐丝
			ElMessage.error({
				message: data.data.message
			})
		}
		if (data.data.code === -9) {
			setTimeout(() => {
				// 先注释
				Router.replace({
					path: '/login',
					query: {},
				})
			}, 1000)
		}
		return
	}
	// 返回方法调用的那里，拿到的就是服务端返回的数据
	if (data.data) {
		return data.data
	}
}, err => {
	// 如果HTTP响应码是400,500等就会进入这个err函数中
	// 如果服务器没启动或者是路径错误进入此判断中
	if (!err || !err.response) {
		ElMessage.error({
			message: '服务器连接失败'
		})
		return
	}
	if (err.response.status === 504 || err.response.status === 404) {
		ElMessage.error({
			message: '服务器连接失败'
		})
	} else if (err.response.status === 403) { // 表示权限不足
		ElMessage.error({
			message: '权限不足，请联系管理员'
		})
	} else if (err.response.status === 401) { // 表示未登录
		ElMessage.error({
			message: err.response.data.msg // 服务器返回来的信息
		})
	} else {
		if (err.response.data.msg) {
			ElMessage.error({
				message: err.response.data.msg
			})
		} else {
			ElMessage.error({
				message: '未知错误'
			})
		}
	}
})
// get请求接口
export const getRequest = (url, params) => {
	let paramStr = ''
	for (let key of Object.keys(params)) {
		let name = params[key];
		if (paramStr === '') {
			paramStr += key + '=' + name
		} else {
			paramStr += '&' + key + '=' + name
		}
	}
	return axios({
		method: 'get',
		url: `${url}` + '?' + paramStr
	})
}
// post请求接口
export const postRequest = (url, params) => {
	return axios({
		method: 'post',
		url: `${url}`,
		data: params,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
// post表单参数接口
export const postFormRequest = (url, params) => {
	return axios({
		method: 'post',
		url: `${url}`, // url的拼接，base就是我们定义的前缀，url就是你传进来的请求
		data: params,
		transformRequest: [function(data) {
			let ret = ''
			for (let it in data) {
				ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
			}
			return ret
		}],
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	})
}
// 文件上传接口
export const uploadFileRequest = (url, params) => {
	return axios({
		method: 'post',
		url: `${url}`,
		data: params,
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
}
// put请求接口
export const putRequest = (url, params) => {
	return axios({
		method: 'put',
		url: `${url}`,
		data: params,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
// delete请求接口
export const deleteRequest = (url) => {
	return axios({
		method: 'delete',
		url: `${url}`
	})
}

// 获取菜单有没有操作权限
export const getMenuOperateAuth = (path) => {
	return axios({
		method: 'post',
		url: '/admin/sysReturnModelOperate',
		data: {
			url: path
		},
		headers: {
			'Content-Type': 'application/json'
		}
	})
}