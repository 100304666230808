import {
	createApp
} from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
//引入ElementPlus
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
// 统一导入el-icon图标
import * as ElIconModules from '@element-plus/icons'

// 引入公用的js
import $ from './assets/js/js.js'
import {
	transElIconName
} from './assets/js/js.js'

// 引入公用的样式文件
import './assets/css/base.css';
import './assets/css/main.css';
// 兼容IE   开始
import '@babel/polyfill'
import Es6Promise from 'es6-promise';

require('es6-promise').polyfill()
Es6Promise.polyfill();
// 兼容IE   结束
// px2rem 自适应
import './assets/js/lib-flexible/flexible.js'
//手机端控制台
// import vConsole from './http/vconsole'
import {
	dragscrollNext
} from 'vue-dragscroll'
// 网络请求
import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
	uploadFileRequest,
	postFormRequest,
	getMenuOperateAuth
} from './http/http.js'
import Print from "vue3-print-nb";
// 引入echarts
import * as echarts from 'echarts'

// 路由拦截判断
router.beforeEach((to, from, next) => {
	// 如果路由不一样，滚动到最顶部
	if (to.name != from.name) {
		window.scrollTo(0, 0);
	}
	if (to.meta.title) {
		$.title(to.meta.title);
	}
	next();
})

const app = createApp(App)

let banIcon = ['Filter', 'Link', 'Menu', 'Picture', 'Select', 'Switch', 'View']
// 统一注册el-icon图标
for (let iconName in ElIconModules) {
	if (banIcon.indexOf(iconName) === -1) {
		app.component(transElIconName(iconName), ElIconModules[iconName])
	}
}

app.use(router)
app.use(ElementPlus)
app.use(Print)
app.directive('dragscroll', dragscrollNext);

app.config.globalProperties.$axios = axios
app.config.globalProperties.$echarts = echarts
app.config.globalProperties.$baseUrl = axios.defaults.baseURL
app.config.globalProperties.$imgPath = axios.defaults.baseURL + '/wechatimages/'
app.config.globalProperties.$method = $
app.config.globalProperties.$get = getRequest
app.config.globalProperties.$post = postRequest
app.config.globalProperties.$delete = deleteRequest
app.config.globalProperties.$put = putRequest
app.config.globalProperties.$uploadFile = uploadFileRequest
app.config.globalProperties.$postForm = postFormRequest
// app.config.globalProperties.$getMenuOperateAuth = f
import Bus from './bus.js' /// mitt 总线程引入
app.config.globalProperties.bus = Bus

app.mount('#app')