import {
	createRouter,
	createWebHashHistory
} from 'vue-router'

const routes = [{
		path: '/index',
		name: 'index',
		component: () => import('@/views/index.vue'),
		meta: {
			title: 'DILC管理监控平台',
			keepAlive: true,
		},
		children: [{ //工作台
			path: '/stagIng',
			name: 'stagIng',
			component: () => import('@/views/stagIng/stagIng.vue')
		}, { //操作日志
			path: '/loggingList',
			name: 'loggingList',
			component: () => import('@/views/logging/loggingList.vue')
		}, { //告警日志
			path: '/alarmList',
			name: 'alarmList',
			component: () => import('@/views/logging/alarmList.vue')
		}, { //系统用户
			path: '/sysUser',
			name: 'sysUser',
			component: () => import('@/views/systemManagement/sysUser.vue')
		}, { //文档管理
			path: '/filesKeeping',
			name: 'filesKeeping',
			component: () => import('@/views/systemManagement/filesKeeping.vue')
		}, { //基本资料
			path: '/basics',
			name: 'basics',
			component: () => import('@/views/systemManagement/basics.vue')
		}, { //设备列表
			path: '/facilityList',
			name: 'facilityList',
			component: () => import('@/views/facility/facilityList.vue')
		}, {
			path: '/goDeviceDetalis',
			name: 'goDeviceDetalis',
			component: () => import('@/views/facility/goDeviceDetalis.vue')
		}, {
			path: '/consumption',
			name: 'consumption',
			component: () => import('@/views/facility/consumption.vue')
		}],
		// { //新增设备
		// 	path: '/goDeviceDetalis',
		// 	name: 'goDeviceDetalis',
		// 	component: () => import('@/views/facility/goDeviceDetalis.vue')
		// }, 
		// children: [{	
		// 	path: '/goodsManage',
		// 	name: 'goodsManage',
		// 	component: () => import('@/views/gift/goodsManage.vue')
		// }, {
		// 	path: '/sportsContract',
		// 	name: 'sportsContract',
		// 	component: () => import('@/views/gift/sportsContract.vue')
		// }, {
		// 	path: '/commentManage',
		// 	name: 'commentManage',
		// 	component: () => import('@/views/gift/commentManage.vue')
		// }, {
		// 	path: '/categoryManage',
		// 	name: 'categoryManage',
		// 	component: () => import('@/views/gift/categoryManage.vue')
		// }, {
		// 	path: '/specManage',
		// 	name: 'specManage',
		// 	component: () => import('@/views/gift/specManage.vue')
		// }, {
		// 	path: '/returnReasonManage',
		// 	name: 'returnReasonManage',
		// 	component: () => import('@/views/gift/returnReasonManage.vue')
		// }, {
		// 	path: '/roleCheck',
		// 	name: 'roleCheck',
		// 	component: () => import('@/views/check/roleCheck.vue')
		// }, {
		// 	path: '/activityCheck',
		// 	name: 'activityCheck',
		// 	component: () => import('@/views/check/activityCheck.vue')
		// }, {
		// 	path: '/teamCheck',
		// 	name: 'teamCheck',
		// 	component: () => import('@/views/check/teamCheck.vue')
		// }, {
		// 	path: '/withdrawCheck',
		// 	name: 'withdrawCheck',
		// 	component: () => import('@/views/check/withdrawCheck.vue')
		// }, {
		// 	path: '/officialActivity',
		// 	name: 'officialActivity',
		// 	component: () => import('@/views/activity/officialActivity.vue')
		// }, {
		// 	path: '/addActivity',
		// 	name: 'addActivity',
		// 	component: () => import('@/views/activity/addActivity.vue')
		// }, {
		// 	path: '/viewActivity',
		// 	name: 'viewActivity',
		// 	component: () => import('@/views/activity/viewActivity.vue')
		// }, {
		// 	path: '/brandActivity',
		// 	name: 'brandActivity',
		// 	component: () => import('@/views/activity/brandActivity.vue')
		// }, {
		// 	path: '/activityPrize',
		// 	name: 'activityPrize',
		// 	component: () => import('@/views/activity/activityPrize.vue')
		// }, {
		// 	path: '/activityStatementRule',
		// 	name: 'activityStatementRule',
		// 	component: () => import('@/views/activity/activityStatementRule.vue')
		// }, {
		// 	path: '/activityStatementDuty',
		// 	name: 'activityStatementDuty',
		// 	component: () => import('@/views/activity/activityStatementDuty.vue')
		// }, {
		// 	path: '/userManage',
		// 	name: 'userManage',
		// 	component: () => import('@/views/user/userManage.vue')
		// }, {
		// 	path: '/goodsOrder',
		// 	name: 'goodsOrder',
		// 	component: () => import('@/views/order/goodsOrder.vue')
		// }, {
		// 	path: '/officialActivityOrder',
		// 	name: 'officialActivityOrder',
		// 	component: () => import('@/views/order/officialActivityOrder.vue')
		// }, {
		// 	path: '/brandActivityOrder',
		// 	name: 'brandActivityOrder',
		// 	component: () => import('@/views/order/brandActivityOrder.vue')
		// }, {
		// 	path: '/sportsContractOrder',
		// 	name: 'sportsContractOrder',
		// 	component: () => import('@/views/order/sportsContractOrder.vue')
		// }, {
		// 	path: '/refundManage',
		// 	name: 'refundManage',
		// 	component: () => import('@/views/order/refundManage.vue')
		// }, {
		// 	path: '/refundAddressManage',
		// 	name: 'refundAddressManage',
		// 	component: () => import('@/views/order/refundAddressManage.vue')
		// }, {
		// 	path: '/adManage',
		// 	name: 'adManage',
		// 	component: () => import('@/views/ad/adManage.vue')
		// }, {
		// 	path: '/scaling',
		// 	name: 'scaling',
		// 	component: () => import('@/views/system/scaling.vue')
		// }, {
		// 	path: '/roleIntroduce',
		// 	name: 'roleIntroduce',
		// 	component: () => import('@/views/system/roleIntroduce.vue')
		// }, {
		// 	path: '/homePageModule',
		// 	name: 'homePageModule',
		// 	component: () => import('@/views/system/homePageModule.vue')
		// }, {
		// 	path: '/heatingRecharge',
		// 	name: 'heatingRecharge',
		// 	component: () => import('@/views/system/heatingRecharge.vue')
		// }, {
		// 	path: '/contactUs',
		// 	name: 'contactUs',
		// 	component: () => import('@/views/system/contactUs.vue')
		// }, {
		// 	path: '/systemUser',
		// 	name: 'systemUser',
		// 	component: () => import('@/views/system/systemUser.vue')
		// }, {
		// 	path: '/roleAuth',
		// 	name: 'roleAuth',
		// 	component: () => import('@/views/system/roleAuth.vue')
		// }, {
		// 	path: '/hobby',
		// 	name: 'hobby',
		// 	component: () => import('@/views/system/hobby.vue')
		// }]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login.vue')
	},
	{
		path: '/',
		redirect: '/login',
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router